import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { HubspotLogo } from "../shared/logos/HubspotLogo";
import { LinkedInLogo } from "../shared/logos/LinkedInLogo";
import { MicrosoftLogo } from "../shared/logos/MicrosoftLogo";
import { MondayLogo } from "../shared/logos/MondayLogo";
import { CalljourneyLogo } from "components/shared/logos/CalljourneyLogo";
import { HypeAndDexterLogo } from "components/shared/logos/HypeAndDexterLogo";
const logos = [
  <HubspotLogo />,
  <MondayLogo />,
  <CalljourneyLogo />,
  <HypeAndDexterLogo />,
  <MicrosoftLogo />,
  <LinkedInLogo />,
];

export const LogoCarousel = () => {
  return (
    <div className="w-full h-full overflow-auto px-4">
      <InfiniteScroll
        dataLength={logos.length}
        hasMore={false}
        className="flex flex-nowrap columnn-gap-1"
        scrollableTarget="scrollableDiv"
        horizontal={true}
      >
        {logos.map((LogoItem, index) => (
          <div className="py-1" key={index}>
            {LogoItem} {/* Render the logo component */}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};
